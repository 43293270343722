<template>
  <div>
    <dashboard-page-title  :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">العيادات</dashboard-page-title>

    <main-table :fields="fields" list_url="admin/clinics"  :baseURL="VUE_APP_VETE_LINK" :additional-url="`&status=accepted`"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'شعار العيادة', key: 'logo', class: 'text-right', type: 'image' },
        { label: 'اسم العيادة', key: 'clinic_name', class: 'text-right' },
        { label: 'عدد الأطباء', key: 'doctors_num', class: 'text-right' },
        { label: 'عدد الحجوزات', key: 'reservations_num', class: 'text-right' },
        { label: 'الحالة', key: 'status', class: 'text-right', type: 'status' },
        { label: 'تاريخ الإنشاء', key: 'created_at', class: 'text-right' }
      ]
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    }
  }
}
</script>
